.won-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10px;
  background-color: #b51963;
}

.won {
  position: absolute;
  left: 30%;
  right: 30%;
  bottom: 30%;
  top: 20%;
  text-align: center;
  margin: auto;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: 0 5 15;
  color: black;
}
