body {
  background-color: #b51963 !important;
  display: flex;
}

.game {
  width: 850px;
  /* max-width: 100%; */
}

.game-container-1 {
  background-color: #b51963;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.drag-and-drop {
  width: 100%;
   /* max-width: 100%; */
}

.three {
  order: 2;
  font-size: xx-large;
}

.game-controls {
  display: flex;
  flex-direction: col;
  gap: 10px;
  justify-content: center;
  padding: 1em;
}

.guess-counts {
  justify-content: center;
  display: flex;
  font-size: 1.25em;
}

.feedback {
  overflow-y: scroll;
  background: #774360;
  width: 100%;
  height: 15em;
  margin-top: 1em;
  padding: 2em;
  font-size: 2em;
  order: 3;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.feedback:hover {
  transform: scale(1.1);
}

#message {
  text-align: center;
}

.game-buttons {
  order: 3;
}

.h2 {
  position: relative;
}
.check-and-submit {
  display: flex;
  text-align: center;
  padding-top: 1em;
}
