.drag {
  background: #774360;
  min-height: 10em;
  border-radius: 25px;
  flex: 1;
  margin: 1em;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px;
  display: flex;
  gap: 5px;
  min-width: 850px;
  /* min-width: 70%;
  max-width:  100%; */
  
}

.drop {
  background: #774360;
  min-height: 10em;
  max-height: 15em;
  border-radius: 25px;
  flex: 1;
  justify-content: space-around;
  margin: 1em;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px;
  display: flex;
  flex-wrap: no-wrap;
  width: 40%;
  gap: 5px;
}

.multiple {
  background: #774360;
  min-height: 10em;
  border-radius: 25px;
  flex: 1;
  margin: 1em;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px;
  display: flex;
  flex-wrap: no-wrap;
  gap: 5px;
  width: 40%;
}

.item {
  background: #b25068;
  padding: 0.5em;
  font-size: 36pt;
  border-radius: 50%;
  cursor: move;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.7s ease-in-out;
}

.active {
  background: #e7ab79;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(5);
  font-size: 36pt;
}

.item:hover {
  background: #e7ab79;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(1.3);
}
